
import { defineComponent, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElInput, FormInstance, FormRules } from 'element-plus'

import { apiErrorNotification } from '@/core/helpers'
import { routeNames } from '@/router'
import { authStore } from '@/store'
import { usePermissions } from '@/core/compositions'

export default defineComponent({
  name: 'ResetView',

  components: { ElInput },

  beforeRouteEnter(to, from, next) {
    const signature = !!to.query?.signature
    const expire = !!to.query?.expire

    if (!signature || !expire) {
      next({ name: routeNames.login })
    }

    next()
  },

  setup() {
    const router = useRouter()
    const route = useRoute()

    const { defaultRedirectRoute } = usePermissions()

    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive({
      email: '',
      password: '',
      confirmPassword: '',
      signature: '',
      expire: 0,
    })

    const formValidationRules = reactive<FormRules>({
      password: [
        {
          required: true,
          message: 'Password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: 'Confirm password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value !== formValues.password) {
              callback(new Error('Passwords not matches!'))
            } else {
              callback()
            }
          },
          message: 'Passwords not matches!',
          trigger: 'change',
        },
      ],
    })

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      formValues.email = route.query.email as string
      formValues.signature = route.query.signature as string
      formValues.expire = parseInt(route.query.expire as string)

      try {
        await authStore.reset(formValues)

        router.push({ name: defaultRedirectRoute.value })
      } catch (error) {
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const toSignInView = () => {
      router.push({ name: routeNames.login })
    }

    return {
      formRef,
      formValues,
      formValidationRules,
      loading,
      onSubmit,
      toSignInView,
    }
  },
})
